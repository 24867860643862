<template>
  <section id="reports">
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </section>
</template>

<script>
export default {
}
</script>
